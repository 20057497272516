.cont-home {
  position: relative;
  top: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: auto;
  margin: 0;
  margin-bottom: 80px;
}
.cont-barraLateral-Y-listaProps {
  position: relative; /* para manejar el titulo */
  width: 100%;
  height: auto;
  display: flex;
  justify-content: start;
  align-items: start;
  background-color: rgb(222, 222, 222);
} 
.cont-titulo-filtros-props{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 12;
  background-color: rgba(234, 231, 229, 1);
}
.cont-filtros-listaProps {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 50px;
  height: auto; /* Asegúrate de que este contenedor pueda crecer */
}
.titulo-lista-props {
  position: absolute;
  top: -20px;
  left: 50%;
  transform: translate(-50%, -50%); /* Centra horizontalmente y ajusta verticalmente */
  width: fit-content; /* Ajusta el ancho al contenido */
  text-align: center;
  font-size: 28px;
  padding: 10px 25px;
  border-radius: 10px;
  color: var(--primary-color);
  background-color: var(--color-negro-logo);
}
.cont-barraLateral {
  position: sticky;
  top: 80px; /* La distancia desde la parte superior de la ventana */
  max-height: calc(100vh - 20px); /* Ajusta la altura máxima para evitar que salga del contenedor */
  overflow-y: auto; /* Permite el desplazamiento dentro de la barra lateral si el contenido es más largo */
}
.cont-listaProps-Y-paginacion {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 70px;
}


/* responsive */
@media screen and (max-width: 1200px){
  .cont-barraL{  
    width: 25%;
  }
}
@media screen and (max-width: 900px){
  .cont-barraL{  
    width: 40%;
  }
}
@media screen and (max-width: 780px){
  .cont-barraLateral-Y-listaProps {
      flex-direction: column;
      justify-content: center;
      align-items: center;
  }
  .cont-barraLateral {
      position: relative;
      height: auto;
      margin: 0;
  }
  .cont-listaProps-Y-paginacion {
      width: 95%;
  }
  .titulo-lista-props {
      position: absolute;
      top: -10px;
      left: 50%;
      transform: translate(-50%, -50%); /* Centra horizontalmente y ajusta verticalmente */
      width: 300px; 
      text-align: center;
      font-size: 15px;
      padding: 10px 25px;
      border-radius: 10px;
  }
}
@media screen and (max-width: 700px){
  .subCont-textos{
    width: 90%;
  }
  .logoTexto{
    height: 4rem;
  }
  .texto-sup{
    font-size: 18px;
  }
  .cont-sup-img,
  .cont-inf-img{
    flex-direction: column;
  }  
  .cont-fondo-img,
  .cont-fondo-oscuro{
    width: 90%;
  }  
  .cont-filtros-listaProps{
    align-items: center;
    margin-top: 30px;
  }
  .cont-filtros-listaProps{
    display: flex;
    flex-direction: column;
  }
  .cont-barraL{
    width: 95%;
    position: inherit;
    margin-left: 5px;
    margin-bottom: 90px;
    z-index: 0;
  }
  .cont-listaProps{
    width: 95%;
  }
  .titulo-conoce-propiedades{
    font-size: 18px;
  }
}
@media screen and (max-width: 400px){
  .subCont-textos{
    width: 90%;
  }
  .logoTexto{
    height: 4rem;
  }
  .texto-sup{
    font-size: 15px;
  }
}