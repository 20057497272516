.contModal{
    width: 80%;
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    z-index: 1000;
}
.cont-btn-cierra-modal{
    width: 70px;
    position: relative;
    top: 30px;
    left: 0;
    z-index: 1;
}
.mesaj-pos-horizontal{
    display: none;
}
.btn-close-modal{
    border-radius: 50%;
    border: none;
    width: auto;
    height: auto;
    color: var(--primary-color);
    background-color: var(--color-negro-logo);
    cursor: pointer;
    font-size: 20px;
}
.cont-carrusel-modal{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
/* responsive */
@media screen and (max-width: 800px) and (min-width: 671px){
    .contModal{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 95vh;
    }
    
}
@media screen and (max-width: 600px){
    .mesaj-pos-horizontal{
        display: flex;
        color: red;
    }
    .contModal{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 95vh;
    }
    
}