.contFooter{
    /*
    position: fixed;
    bottom: 0; */ /*SI quiero q qde fija como pie de pag o en el view heigth*/
    /* left: 0; */
    width: 100%;
    height: auto;  
    display: flex;
    flex-direction: column;
    justify-content: center;  
    align-items: center;
    background-color: var(--color-negro-logo);
}
.footer {
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;;
}
.divF{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}
footer ul {
    display: flex;
    width: 100%;
}
.logo-footbar {
    width: 150px;
    height: 150px;
}
footer ul li {
    display: inline-block;
    position: relative;
    width: 35%;
}
/* color linea sup y titulo*/
footer ul li h2 {
    margin-bottom: 3px;
    font-size: 18px;
    font-weight: 200;
    color: var(--primary-color);
    border-bottom: 1px solid var(--primary-color);
}
/* textos contáctenos */
.info-contactos{
    margin: 3px 0 3px 0;
    font-size: 17px;
    font-weight: 200;
    color: var(--primary-color);
}
.titulo-col-foot{
    font-size: 15px;
    font-weight: 200;
}
.divLinks{
    display: flex;
    flex-direction: column;
}
/* textos Link */
.link-footbar{
    font-size: 15px;
    font-weight: 200;
    color: var(--primary-color);
}
.contTodasMedidas{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;  
}
/* color linea inferior */
.line {
    margin-top: 10px;
    width: 100%; /* Ancho de la línea */
    height: 1px; /* Grosor de la línea */
    background-color: var(--primary-color); /* Color de la línea */    
    margin-bottom: 5px;
}
.icono-redes-foot{
    width: 50px;
    height: 50px;
}
.cont-info-derechos{
    width: 90%;
    border-top: 1px solid var(--primary-color);
}
.info-derechos{
    font-size: 12px;
    color: var(--dorado-oscuro);
}

@media screen and (max-width: 900px){
    .footer {
        width: 90%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
@media screen and (max-width: 750px){
    footer ul li {
        text-align: center;
    }
}
@media screen and (max-width: 650px) {
    .divF {
        flex-direction: column;
        align-items: center;
    }
    .logo-footbar {
        width: 140px;
        height: 140px;
        margin-top: 20px;
    }
    footer ul {
        flex-direction: column;
        align-items: center;
        width: 90%;
    }
    footer ul li {
        display: block;        
        position: relative;
        width: 90%;
        margin-bottom: 10px;
    }
    footer ul li h2 {
        width: 100%;
    }
    .divLinks{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 5px;
        width: 90%; 
    }
    footer ul li h4 p, footer ul li p, footer ul li .link {
        font-size: 15px;
        line-height: 17px;
        display: flex;
        justify-content: center;
    }
    .cont-iconos-redes{
        display: flex;
        justify-content: center;
        align-items: center;
    }
} 


