.cont-page-favoritos{
    position: relative;
    top: 80px;
    width: 100%;
    min-height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;    
    margin-bottom: 80px;
    background-color: var(--color-gris);
}
