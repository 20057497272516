.cont-Venta{
    position: relative;
    top: 80px;
    width: 100%;
    min-height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    margin-bottom: 80px;
    background-color: var(--color-gris);
}
.cont-titulo-y-props-venta{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0;
}
.cont-titulo-venta{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.titulo-props-venta{
    font-size: 25px;
    font-weight: 300;
}
.cont-barraLateral-Y-listaProps-venta{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: start;
    margin-top: 10px;
}
.cont-barraLateral-venta{
    position: sticky;
    top: 90px; /* La distancia desde la parte superior de la ventana */
    max-height: calc(100vh - 20px); /* Ajusta la altura máxima para evitar que salga del contenedor */
    overflow-y: auto; /* Permite el desplazamiento dentro de la barra lateral si el contenido es más largo */
}
.cont-listaProps-Y-paginacion-venta{
    width: 85%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
@media screen and (max-width:700px){
    .cont-Venta{
        margin: 0;
    }
    .cont-barraLateral-Y-listaProps-venta{
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: center;
        margin: 0;
    }
    .cont-titulo-venta{
        width: 100%;
    }
    .titulo-props-venta{
        font-size: 25px;
        font-weight: 300;
        margin-top: 10px;
        text-align: center;
    }
    .cont-barraLateral-venta{
        height: auto;
        margin: 0 0 10px 0;
        position: relative;
        top: 10px;
    }
}