nav {
    position: fixed;
    top: 0;
    left: 0; /* si quiero nav fija */
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 80px;
    z-index: 100;
    background-color: var(--color-negro-logo);
}
.cont-principal-nav{
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
/* cont logo */
.cont-logo{
    width: 25%;
    height: 80px;
    display: flex;
    justify-content: center;    
    align-items: center;
}
.logo-nav{
    height: 4rem;
}
/* cont barra sup e inf */
.cont-nav-sup-inf{
    width: 75%;
    height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.nav-sup, 
.nav-inf {
    width: 100%;
    height: 40px;
    background-color: var(--color-negro-logo);
}
.nav-sup {
    display: flex;
    justify-content: end;
}
/* texto nav sup */
.texto-barra-sup{
    color: var(--primary-color);
}
.cont-data-nav-sup-direcc,
.cont-data-nav-sup-tel,
.cont-data-nav-sup-email,
.cont-data-nav-sup-insta,
.cont-data-nav-sup-whatsApp {
    width: auto;
    display: flex;
    justify-content: end;
    align-items: center;
}
.iconoContact{
    margin-left: 5px;
    margin-right: 5px;
    color: var(--primary-color);
}
.iconoInstaNew{
    width: 40px;
    height: 40px;
}
/* nav inf */
.nav-inf{
    display: flex;
    justify-content: center;
    align-items: center;
}
.ul-nav-inf{
    display: flex;
}
/* texto nav inf */
.ul-nav-inf > li {
    list-style: none;
    font-size: 24px;
    font-weight: 100;
    margin-right: 20px;
}
/* quito line y cambio color etiqtas a y Link */
a, Link{
    color: var(--primary-color);
    text-decoration: none;
}
/* menú hamburg */
.menu-icon span {
    width: 100%;
    height: 3px;
    background-color: var(--primary-color);
    transition: all 0.3s ease;
}
.menu-icon.open span:nth-child(2) {
    opacity: 0;
}
.menu-icon.open span:nth-child(1) {
    transform: translateY(8px) rotate(45deg);
}
.menu-icon.open span:nth-child(3) {
    transform: translateY(-8px) rotate(-45deg);
}
.menu-desplegable {
    position: absolute;
    top: 70px;
    right: 0;
    background: var(--color-negro-logo);
    width: auto;
    padding: 5px;
    overflow: hidden;
    transition: all 0.3s ease;
    z-index: 3;
}
.ul-lista-pChica {
    list-style-type: none;
    padding: 0;
}
.menu li {
    padding: 10px;
    border-bottom: 1px solid #ddd;
}
.link-navbar{
    color: var(--primary-color);
    text-decoration: none;
}
.menu li:hover {
    background: var(--background-color);
}
.items-pChica{
    margin: 10px 0 10px 0;
}

/* responsive */
@media screen and (max-width: 1260px) {
    .menu-icon {
        display: none;
    }
    /* cont logo */
    .cont-logo{
        width: 25%;
    }
    .logo-nav{
        height: 3rem;
    }
    .texto-barra-sup{
        font-size: 15px;
    }
    .cont-nav-inf{ /* usando flexbox */
        display: flex;
        flex-wrap: wrap;
    }
    /* texto nav inf */
    .ul-nav-inf > li {
        font-size: 20px;
    }
    
}
@media screen and (max-width: 1100px) {
    .menu-icon {
        display: none;
    }
    .cont-principal-nav{
        width: 99%;
    }
    /* cont logo */
    .cont-logo{
        width: 28%;
    }
    .cont-nav-sup-inf{
        width: 72%;
    }
    .iconoContact{
        margin-left: 5px;
        margin-right: 5px;
    }
    .iconoInstaNew{
        width: 30px;
        height: 30px;
    }
    .texto-barra-sup{
        font-size: 13px;
    }
    .cont-nav-inf{ /* usando flexbox */
        display: flex;
        flex-wrap: wrap;
    }
    /* texto nav inf */
    .ul-nav-inf > li {
        font-size: 17px;
    }
    
}
@media screen and (max-width: 850px) {
    /* cont logo */
    .cont-logo{
        width: 90%;
    }
    .logo{
        height: 2.5rem;
    }
    .cont-nav-sup-inf{
        display: none;
    }
    .iconoContact{
        margin-left: 3px;
        margin-right: 3px;
    }
    .iconoInstaNew{
        width: 25px;
        height: 25px;
    }
    .texto-barra-sup{
        font-size: 13px;
    }
    /* menu P.chica lo debo declarar acá, no arriba*/
    .cont-menu-hambur{
        width: 10%;
        display: flex;
        justify-content: center;    
        align-items: center;
    }
    .menu-icon {
        width: 30px;
        height: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        cursor: pointer;
    }
}