.cont-landing-C{
    width: 100%;
    height: 707px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    margin-bottom: 20px;
}
.cont-fila-1 {
    width: 60%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.titulo-land-C {
    text-align: center;
    font-size: 22px;
    color: var(--color-negro);
    margin: 30px 0 0 0;
}
.texto-2-land-C {
    text-align: center;
    font-size: 25px;
    font-weight: 500;
    margin: 10px 0px;
    color: var(--color-negro);
}
.cont-fila-2 {
    width: 80%;
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 50px 0 50px 0;
}
.col-1,
.col-2, 
.col-3, 
.col-4 {
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.texto-fila-2 {
    font-size: 18px;
    font-weight: 400;
    text-align: center;
}
.cont-fila-3 {
    width: 80%;
    height: 300px;
    display: flex;
    justify-content: start;
    align-items: center;
    margin-bottom: 30px;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0)), url('../../Imagenes/img-3.jpg');
    background-size: cover; 
    background-position: center;
}
.cont-inf-fila-3 {
    width: 80%;
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    margin: 10px 0 0 50px;
}
.texto-1-fila-3 {
    font-size: 18px;
    font-weight: 200;
    margin: 0 0 10px 0;
    color: var(--primary-color);
}
.texto-2-fila-3 {
    font-size: 25px;
    font-weight: 400;
    margin: 0;
    color: var(--primary-color);
}
.texto-3-fila-3 {
    width: 70%;
    font-size: 18px;
    color: var(--primary-color);
}
.btn-fila-3 {
    font-weight: 400;
    color: var(--color-blanco);
    background-color: #3AE7F000;
    transition-duration: 0.1s;
    border-style: solid;
    border-width: 1px 1px 1px 1px;
    border-color: var(--color-blanco);
    border-radius: 10px 10px 10px 10px;
    padding: 15px 50px 15px 50px;
    cursor: pointer;
}

/* responsive */
@media screen and (max-width: 800px){
    .cont-landing-C{
        height: auto;
    }
    .cont-fila-1 {
        width: 90%;
    }
    .cont-fila-2 {
        width: 90%;
        height: auto;
        display: flexbox;
        flex-wrap: wrap; /* apila */
        justify-content: space-around;
        align-items: center;
        margin: 50px 0 50px 0;
    }
    .cont-fila-3 {
        width: 95%;
        height: auto;
        
    }
    .texto-3-fila-3 {
        font-weight: 200
    }
    .btn-fila-3 {
        margin-bottom: 10px;
    }
}