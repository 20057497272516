.contGralCarruselM{
    width: 751px;
    height: 590px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.cont-img-btns{
    position: relative;
}
.btn-carrusel-prev, 
.btn-carrusel-next{
    width: auto;
    height: auto;
    position: absolute;
    top: 50%;
    display: flex;
    justify-content: center;
    transform: translateY(-50%);
    background-color: transparent;
    border: none;
    color: rgb(9, 9, 9);
    font-size: 18px;
    cursor: pointer;
    z-index: 2;
}
.btn-carrusel-prev{
    left: 10px;
}
.cont-img-carruselM-btnCierraModal{
    position: relative;
    width: 100%;
}
.cont-btn-cierra-modal{
    width: 70px;
    position: absolute;
    display: flex;
    justify-content: center;
    top: 0px;
    left: 5px;
    z-index: 1;
}
.img-carruselM{
    width: 100%;
    max-height: 586px;
    border-radius: 10px;
}
.btn-carrusel-next{
    right: 10px;
}

/* responsive */
@media screen and (max-width: 800px) and (min-width: 671px){
    .contGralCarruselM{
        position: relative;
        max-width: 80%;
        max-height: 60%;
        top: -80px;
        justify-content: start;
    }
    .cont-img-carruselM-btnCierraModal{
        position: relative;
        width: 100%;
        top: -15px;
    }
    .img-carruselM{
        max-height: 350px;
    }
}
@media screen and (max-width: 670px){
    .contGralCarruselM{
        max-width: 80%;
        max-height: 80%;
        justify-content: start;
    }
}