.paginacion-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
  .paginacion-button {
    background-color: var(--color-negro-logo);
    color: var(--primary-color);
    border: none;
    padding: 10px 20px;
    margin: 0 10px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 16px;
  }
  
  .paginacion-button:disabled {
    background-color: #ccc;
    color: black;
    cursor: not-allowed;
  }
  
  .paginacion-info {
    font-size: 16px;
    color: black;
  }
  
  /* responsive */
  @media screen and (max-width: 530px){
    .paginacion-container {
      margin-bottom: 20px;
    }
  }