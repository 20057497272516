.cont-texto-imagenes{
    height: 700px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--color-negro-logo);
}
.subCont-textos{
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    margin-top: 50px;
}
.texto-sup{
    font-size: 25px;
    margin-top: 80px;
    color: var(--primary-color);
}
.logoTexto{
    height: 8rem;
}
/* Botón contacto */
.btn-contactanos-home{
    margin-top: 40px;
    width: 200px;
    padding: 10px 8px;
    border: 1px solid var(--primary-color);
    border-radius: 20px;
    font-size: 25px;
    font-weight: 100;
    color: var(--primary-color);
    background-color: transparent;
    cursor: pointer;
    box-shadow: 0 0 0 0 rgba(52, 152, 219, 0.7);
    animation: pulse 2s infinite;
    margin-bottom: 80px;
}
@keyframes pulse {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(52, 152, 219, 0.7);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(52, 152, 219, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(52, 152, 219, 0);
    }
}
.texto-somos{
    font-size: 30px;
    color: var(--primary-color);
}


/* responsive */
@media screen and (max-width: 500px){
    .logo-landing-B {
        height: 17rem;
        border-radius: 10px;
    }
    .texto-inf-landingB {
        font-size: 18px;
        padding: 5px 10px;
    }
}