.contGralDetalle{
    position: relative;
    top: 80px;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: start; /* eje y */
    background: url('../../Imagenes/Texturas/Textura-02.jpg');
    margin-bottom: 80px;
}
.cont-detail{
    width: 95%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 10px;
    background: url('../../Imagenes/Texturas/Textura-04.jpg');
}
/* estilos seccion titulo y btn atras, video */
.info-1{
    width: 92%;
    height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    margin-top: 10px;
    margin-bottom: 10px;
}
.cont-titulo-detalle {
    width: 99%;
    height: 70%;
    display: flex;
    justify-content: start;
    align-items: center;
}
.cont-btns-direccion{
    width: 72%;
    height: 30%;
    display: flex;
    justify-content: center;
    justify-content: space-between;
    align-items: center;
}
.cont-titulo-icono-direcc{
    display: flex;
    justify-content: center;
    align-items: center;
}
.btn-volver,
.btn-video {
    display: flex;
    align-items: center;
    width: auto;
    color: var(--primary-color);
    background-color: var(--background-color);
    border-radius: 10px;
    font-family: Poppins;
    font-weight: 800;
    font-size: medium;
    cursor: pointer;
}
.btn-video:hover,
.btn-volver:hover {
    color: var(--background-color);
    background-color: var(--primary-color);
}
.tooltip {
    position: absolute;
    bottom: 150; /* Position the tooltip above the button */
    left: 55%;
    width: 150px;
    visibility: visible;
    background-color: rgb(221, 8, 8);
    color: #fff;
    text-align: center;
    border-radius: 4px;
    padding: 5px;    
    z-index: 1;    
    opacity: 0.9;
    transition: opacity 0.3s;
}
.tooltipVolver {
    left: 150px;
    width: auto;
    bottom: 150; /* Position the tooltip above the button */  
    visibility: visible;
    background-color: rgb(221, 8, 8);
    color: #fff;
    text-align: center;
    border-radius: 4px;
    padding: 5px;
    position: absolute;
    z-index: 1;  
    opacity: 0.9;
    transition: opacity 0.3s;
}
.detalle-titulo-prop{
    margin-left: 10px;
    font-size: 28px;
    font-weight: 300;
    color: var(--primary-color);
}
.detalle-titulo-direccion{
    margin: 3px;
    font-size: 20px;
    color: var(--primary-color);
}
.detalle-precio{
    margin-right: 10px;
    font-size: 30px;
    font-weight: 500;
    color: var(--primary-color);
    background-color: #070707;
}
/* estilos seccion imagenes */
.cont-imgs-info{
    width: 95%;
    height: auto;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 20px;
}
.cont-imagenes{
    width: 60%;
    height: 580px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.cont-form-contacto{
    position: relative;
    top: 45px;
    width: 25%;
    height: 700px;
}
.info-textos{
    width: 35%;
    height: 550px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    margin-left: 20px;
    color: black;
    border-radius: 10px;
    background-color: #fff;
}
.info-textos span{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50px;
    border-radius: 10px 10px 0 0;
    color: var(--primary-color);
    background-color: rgba(63, 62, 62, 0.7); 
    font-family: Poppins;
    font-weight: 800;
    font-size: large;  
}
.info-textos .cont-datos {
    display: flex;

}
.info-textos p {
    font-family: Poppins;
    font-weight: 700;
    font-size: large;
    margin: 5px 0 5px 10px;
}
.info-textos b {
    font-weight: 800;
}
/* cont descr  */
.cont-titulo-descripcion-form{
    display: flex;
    justify-content: space-around;
    align-items: start;
    width: 95%;
}
.cont-descrip{
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    width: 47.5%;
    min-height: 380px;
    height: auto;
    border-radius: 20px;
    background-color: #fff;
}
.caracteristicas-prop{
    width: 47.5%;
    height: auto;
}
.titulo-descrip-prop {
    color: var(--color-negro-logo);
    font-size: 28px;
    font-weight: 200;
    margin: 0;
    padding: 15px;
    width: 90%;
    border-bottom: 1px solid var(--color-negro-logo);
}
.p-descrip-detalle{
    font-size: 20px;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif ;
    font-weight: 300;
    padding: 5px 20px;
    color: rgb(63, 63, 63);
}
.col-descrip-prop{
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.col-descrip-prop-1 {
    width: 48%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 8px;
}
.cont-p-col-1 {
    width: 88%;
    display: flex;
    justify-content: space-around;
    background-color: var(--color-gris);
    margin-top: 3px;

}
.p-col-1 {
    font-size: 20px;
    font-weight: 300;
    color: rgb(63, 63, 63);
}
/* para formulario */
.formulario-detalle{
    width: 35%;
}
/* mapa */
.cont-map{
    width: 70%;
    height: 550px;
    margin-top: 20px;
}
.p-titulo-mapa{
    width: 250px;
    font-size: 23px;
    font-weight: 200;
    padding: 10px;
    border-radius: 10px;
    color: var(--color-negro-logo);
    background-color: var(--color-gris);
}
/* responsive */

@media screen and (max-width: 1100px){
    .info-1{
        width: 90%;
        justify-content: center;
        align-items: center;
    }
    .cont-titulo-detalle{
        justify-content: center;
        align-items: center;
    }
    .cont-imgs-info {
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .cont-imagenes{
        width: 80%;
        height: auto;
    }
    .cont-form-contacto{
        width: 80%;
        height: auto;
    }
    .cont-titulo-descripcion-form{
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 50px;
    }
    .cont-descrip{
        width: 80%;
        margin-bottom: 20px;
    }
}
@media screen and (max-width: 750px){
    .info-1{
        width: 95%;
        display: flex;
        height: auto;
    }
    .cont-info-titulo{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .cont-info-precio{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .detalle-titulo-prop,
    .detalle-titulo-direccion {
        font-size: 18px;   
    }
    .detalle-precio {
        margin-right: 10px;
        font-size: 20px;
    }
    .cont-imgs-info{
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 0;
    }
    .cont-imagenes{
        width: 90%;
    }
    .info-imagenes{
        width: 70%;
    }
    .info-imagenes, .info-textos{
        width: 70%;
        margin-left: 0;
        margin-top: 15px;
    }
    /* cont form */
    .cont-form-contacto{
        width: 90%;
        height: auto;
        margin-top: 20px;
        margin-bottom: 20PX;
    }
    /* cont descripción */
    .cont-titulo-descripcion-form{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .cont-descrip{
        width: 90%;
        height: auto;
        margin-bottom: 20px;
    }
    /* mapa */
    .cont-map{
    width: 90%;
    height: 550px;
    margin-top: 20px;
    }
}
@media screen and (max-width: 550px){
    .info-1{
        width: 95%;
    }
    .cont-titulo-detalle {
        width: 99%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .cont-btns-direccion{
        width: 95%;
        display: flex;
        justify-content: center;
        justify-content: space-between;
        align-items: center;
    }
    .detalle-titulo-prop{
        margin-left: 10px;
        font-size: 20px;
        font-weight: 300;
        color: var(--primary-color);
    }
    .detalle-titulo-direccion{
        margin: 3px;
        font-size: 17px;
        color: var(--primary-color);
    }
    .cont-imagenes{
        width: 100%;
    }
    /* cont form */
    .cont-form-contacto{
        width: 90%;
        height: auto;
        margin-top: 20px;
        margin-bottom: 20PX;
    }
    /* cont descripción */
    .cont-titulo-descripcion-form{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .cont-descrip{
        width: 95%;
        height: auto;
        margin-bottom: 20px;
    }
    .col-descrip-prop-1 {
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        margin-bottom: 8px;
    }
    .cont-p-col-1 {
        width: 95%;
        display: flex;
        justify-content: space-around;
        background-color: var(--color-gris);
        margin-top: 3px;
    
    }
    .p-col-1 {
        font-size: 15px;
        font-weight: 300;
        color: rgb(63, 63, 63);
    }
    /* mapa */
    .cont-map{
    width: 90%;
    height: 550px;
    margin-top: 0px;
    }
}
@media screen and (max-width: 450px) {
    .detalle-titulo-prop{
        font-size: 15px;
    }
    /* estilos seccion imagenes */
    .cont-imgs-info{
        width: 98%;
        height: auto;
        display: flex;
        justify-content: start;
        align-items: center;
        margin-bottom: 20px;
    }
    .cont-imagenes{
        width: 95%;
        height: auto;
        margin: 0;
    }
    .cont-form-contacto{
        width: 98%;
        height: auto;
        margin-bottom: 20px;
    }
    /* cont descr  */
    .cont-titulo-descripcion-form{
        width: 98%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin-top: 20px;
    }
}