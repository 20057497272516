.contCard {
    position: relative; /* es para Al paea el mouse funcione la opcacidad y msj detalle */
    display: grid;
    grid-template-columns: 380px; /* ANCHO CARD */
    grid-template-rows: 50px 270px 155px 100px;
    grid-template-areas: "title" "image" "info1" "info2";
    /* box-shadow:  2px 5px rgba(0, 0, 0, 0.9); */
    font-family: roboto;
    text-align: center;
    margin: 0 20px 20px 0;
    border-radius: 15px;
}
/* espacio titulo */
.card-title {
    grid-area: title;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--primary-color);
    background-color: var(--color-negro-logo);
    border-radius: 15px 15px 0 0;
    
}
.titulo-card{
    font-size: 28px;
    font-weight: 200;
}
.navLink-car{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
/* manejo espacio img */
.card-image {
    width: 100%;
    max-height: 270px;
    grid-area: image;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden; /* Asegura que la imagen no se desborde del contenedor */
}
.card-img {
    width: 100%; /* Se ajusta al 100% del ancho del contenedor */
    height: auto; /* Mantiene la proporción de la imagen */
    transition: transform 0.3s ease;
}

/* estilos msj detalle */
.detail {
    position: absolute;
    top: 40px;
    left: 50;
    width: 100%;
    height: 280px;
    background-color: rgba(0, 0, 0, 0.7);
    color: #fff;
    display: none;
    justify-content: center;
    align-items: center;
    text-align: center;
    opacity: 0;
    transition: opacity 0.3s ease;
}
.detail.show {
    display: flex;
    opacity: 1;
}
.palabra-abre-detalle{
    font-size: 25px;
    font-weight: 200;
}
/* manejo de info1 */
/* de acá le doy color a todas las palabras contenidas */
.card-info1 {
    grid-area: info1;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;    
    color: var(--primary-color);
    background-color: var(--color-negro-logo);
}
.cont-info1{
    display: flex;
    justify-content: start;
    align-items: center;
    margin: 3px 0 3px 3px;
}
.cont-titulo-publicacion{
    width: 95%;
    margin: 5px 0 3px 0;    
    background-color: var(--color-negro-logo);
}
/* titulo card */
.tituloPublicacion {
    font-size: 20px;
    font-weight: 200;
    text-align: center;
}
.direccion-card {
    font-size: 17px;
    font-weight: 100;
    margin: 0;
    color: var(--dorado-oscuro);
}
.cont-precio-fav{
    width: 100%;
    height: 40px;    
    display: flex;
    justify-content: end;
    align-items: center;
    background-color: grey;
}
.cont-precio {
    width: 100%;
    display: flex;
    justify-content: end;
    align-items: center;
    margin-right: 10px;
}
.cont-fav {
    position: absolute;
    top: 45px;
    left: 90%;
    display: flex;
    justify-content: end;
    align-items: center;
}
.precio{
    margin-left: 10px;
    font-size: 28px;
    font-weight: 500;
    color: var(--primary-color);
}
/* manejo de info2 */
.card-info2 {
    width: 100%;
    grid-area: info2;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    justify-content: center;
    align-items: center;
    border-radius: 0 0 15px 15px;
    background-color: var(--color-negro-logo);
}
.div-info2{
    width: 100%;    
}
.info2,
.icono-info-2 {
    margin: 3px;
    font-size: 17px;
    color: var(--primary-color);
}
.icono-info-2{
    width: 20px;
    height: 20px;
}

/* responsive */
@media screen and (max-width: 580px) {
    .contCard {
        grid-template-columns: 370px; /* ANCHO CARD */
        margin: 0 0 20px 0;
    }
}

@media screen and (max-width: 390px) {
    .contCard {
        grid-template-columns: 100%; /* Ajusta el ancho al 100% del contenedor */
        grid-template-rows: auto; /* Ajusta las filas según el contenido */
        margin: 0 auto 20px auto; /* Centra la card y ajusta el margen */
    }
    .card-title {
        height: 50px;
        
    }
    .cont-precio-fav{
        width: 100%;
        height: 30px;
    }
    .precio{
        margin-left: 10px;
        font-size: 23px;
        font-weight: 500;
        color: var(--primary-color);
    }
    .cont-fav {
        position: absolute;
        top: 80px;
        left: 90%;
        display: flex;
        justify-content: end;
        align-items: center;
    }
    .div-info2{
        margin-top: 8px;
        margin-bottom: 3px;
    }
}



